<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header profile_edit">
            <div class="flexH width">
                <a
                    class="icon iconButton third"
                    @click.prevent="$router.push('/profile')"
                >
                    <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
                </a>
            </div>
            <span class="fL nowrap">編輯基本資料</span>
            <div class="flexH width right"></div>
        </section>
        <!-- Main -->
        <section class="main profile_edit">
            <div class="info flexV width padding center">
                <!-- <a class="img" v-if="displayUserImage"><img :src="displayUserImage" /></a>
                <a class="img" v-else><img src="@/assets/img/profile-d.png" /></a> -->
                <!-- <div class="uide-profile_edit-width  " @click.prevent="$router.push('/profile/edit/phone')" >
                    <div class="board-pad solid border-c01 rounded-xl cNeutral900 flex h-fit">

                        <div class="flex-7 gap-1 flex flex-col  ">
                        <div class="text-base">
                            變更會員帳號手機號碼
                        </div>
                        <div class="text-lg">
                            +886 983998012
                        </div>

                    </div>
                    <div class="flex-3 flex justify-right">
                        <img width="24px" src="@/assets/icon/uide/chevron-01.svg" />
                    </div>
                    </div>
                    
                </div> -->
                <div class="mt-4 mb-4 uide-profile_edit-width">
                    <div class="text-xl cNeutral900">會員資料更新</div>
                    <div class="text-lg cNeutral900">填寫數位資料成為數位縣民，取得數位縣民優惠，並可在雲林幣特約商店中兌換等值商品或服務，如雲林縣區域路邊停車費等。</div>

                </div>
                <div class="input rounded">
                    <!-- class: error -->
                    <div class="icon"><font-awesome-icon icon="fa-solid fa-user"></font-awesome-icon></div>
                    <span class="fM nowrap gray cNeutral500">姓名<span class="red">*</span></span>
                    <input
                        type="text"
                        class="fM text-right pl-4"
                        placeholder="請填寫"
                        v-model="userData.name"
                    />
                    <!-- <span class="fS error message">必填</span> -->
                </div>
                <div class="dropdown rounded">
                    <div class="icon">
                        <img width="16px" src="@/assets/icon/uide/map.svg" />
                    </div>
                    <span class="fM nowrap gray">居住縣市<span class="red">*</span></span>
                    <select
                        name="type"
                        class="fM rounded"
                        dir="rtl"
                        v-model="userData.city"
                    >
                        <option
                            v-for="(city, index) in cities"
                            :key="'city' + index"
                            :value="city.value"
                        >
                            {{ city.text }}
                        </option>
                    </select>
                    <div class="arrow">
                        <font-awesome-icon icon="fa-solid fa-caret-down" size="xs"></font-awesome-icon>
                    </div>
                </div>
                <div class="dropdown rounded">
                    <div class="icon">
                        <img width="16px" src="@/assets/icon/uide/map.svg" />
                    </div>
                    <span class="fM nowrap gray">居住區域<span class="red">*</span></span>
                    <select
                        name="type"
                        class="fM rounded"
                        dir="rtl"
                        v-model="userData.district"
                    >
                        <option
                            v-for="(region, index) in selectRegion"
                            :key="'region' + index"
                            :value="region.value"
                        >
                            {{ region.text }}
                        </option>
                    </select>
                    <div class="arrow">
                        <font-awesome-icon icon="fa-solid fa-caret-down" size="xs"></font-awesome-icon>
                    </div>
                </div>
               
                <div class="input rounded ">
                    <!-- class: error -->
                    <div class="icon"><font-awesome-icon icon="fa-solid fa-calendar"></font-awesome-icon></div>
                    <span class="fM nowrap gray">生日</span>
                    <input class="fM date" type="date" v-model="userData.birthdate" />
                    <span class="fS error message">必填</span>
                </div>
                <div class="dropdown rounded">
                    <div class="icon">
                        <font-awesome-icon icon="fa-solid fa-venus-mars"></font-awesome-icon>
                    </div>
                    <span class="fM nowrap gray">性別</span>
                    <select
                        name="type"
                        class="fM rounded"
                        dir="rtl"
                        v-model="userData.gender"
                    >
                        <option
                            v-for="(gender, index) in genderType"
                            :key="'gender' + index"
                            :value="gender.value"
                        >
                            {{ gender.text }}
                        </option>
                    </select>
                    <div class="arrow">
                        <font-awesome-icon icon="fa-solid fa-caret-down" size="xs"></font-awesome-icon>
                    </div>
                </div>
                <div class="dropdown rounded">
                    <div class="icon">
                        <img width="16px" src="@/assets/icon/uide/map.svg" />
                    </div>
                    <span class="fM nowrap gray">產業類別<span class="red">*</span></span>
                    <select
                        name="type"
                        class="fM rounded"
                        dir="rtl"
                        v-model="userData.industryType"
                    >
                        <option
                            v-for="(industry, index) in industries"
                            :key="'industry' + index"
                            :value="industry.value"
                        >
                            {{ industry.label }}
                        </option>
                    </select>
                    <div class="arrow">
                        <font-awesome-icon icon="fa-solid fa-caret-down" size="xs"></font-awesome-icon>
                    </div>
                </div>
                <div class="dropdown rounded">
                    <div class="icon">
                        <img width="16px" src="@/assets/icon/uide/map.svg" />
                    </div>
                    <span class="fM nowrap gray">職業類型<span class="red">*</span></span>
                    <select
                        name="type"
                        class="fM rounded"
                        dir="rtl"
                        v-model="userData.occupationType"
                    >
                        <option
                            v-for="(profession, index) in professions"
                            :key="'profession' + index"
                            :value="profession.value"
                        >
                            {{ profession.label }}
                        </option>
                    </select>
                    <div class="arrow">
                        <font-awesome-icon icon="fa-solid fa-caret-down" size="xs"></font-awesome-icon>
                    </div>
                </div>
                <div class="input rounded">
                    <div class="icon">
                        <img width="16px" src="@/assets/icon/uide/map.svg" />
                    </div>
                    <span class="fM nowrap gray">身分證字號</span>
                    <input
                        type="text"
                        class="fM text-right pl-4"
                        :class="userData.idno ? 'disabled': ''"
                        placeholder="請填寫"
                        :disabled="userData.idno"
                        v-model="form.idno"
                    />
                </div>
                <!-- <div class="input rounded">
                    <div class="icon"><img width="16px" src="@/assets/icon/uide/recommend.svg" /></div>
                    <span class="fM nowrap gray cNeutral500">推薦人碼</span>
                    <input
                        type="text"
                        class="fM text-right pl-4"
                        placeholder="請填寫對方的推薦人碼"
                        v-model="userData.inviteId"
                    />
                </div> -->
                <!-- <div class="mt-4 mb-4 uide-profile_edit-width">
                    <div class="text-base cNeutral900">請填寫推薦人碼，註冊成功後推薦人才會獲得獎勵喔！</div>                    
                </div> -->
                <div class="mt-4 mb-4 uide-profile_edit-width">
                    <div class="text-xl cNeutral900">其他基本資料</div>                    
                </div>
                <div class="input rounded">
                    <!-- class: error -->
                    <div class="icon"><font-awesome-icon icon="fa-solid fa-envelope"></font-awesome-icon></div>
                    <span class="fM nowrap gray">Email</span>
                    <input
                        type="text"
                        class="fM text-right pl-4"
                        placeholder="請填寫"
                        v-model="userData.email"
                    />
                    <!-- <span class="fS error message">必填</span> -->
                </div>
                
                <!-- <div class="input rounded disabled">                    
                    <div class="icon"><font-awesome-icon icon="fa-solid fa-phone"></font-awesome-icon></div>
                    <span class="fM nowrap gray">手機號碼</span>
                    <input
                        type="text"
                        class="fM"
                        placeholder=""
                        v-model="userData.phoneNumber"
                    />
                    
                </div> -->
                
                <a class="button rounded" @click.prevent="save()">
                    <div class="icon">
                        <font-awesome-icon icon="fa-solid fa-floppy-disk"></font-awesome-icon>
                    </div>
                    <span class="fM">儲存</span>
                    <!-- 提交申請 -->
                </a>
            </div>
        </section>
        <!-- Popup (已儲存) -->
        <section id="popSuccess" class="popup">
            <div class="popBody">
                <section class="popHeader">
                    <!-- <span class="fL">Title</span> -->
                </section>
                <section class="popMain">
                    <div class="icon success">
                        <font-awesome-icon icon="fa-solid fa-circle-check" size="2x"></font-awesome-icon>
                    </div>
                    <span class="fL">已儲存</span>
                </section>
                <section class="popFooter">
                    <a
                        class="button submit rounded"
                        @click.prevent="popClose()"
                    >
                        <span class="fM">確定</span>
                    </a>
                </section>
            </div>
        </section>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "ProfileEdit",
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            genderType: [
                {
                    value: 0,
                    text: "男"
                },
                {
                    value: 1,
                    text: "女"
                }
            ],
            cities:[{
                value:'雲林縣',
                text:'雲林縣',
            }],
            regions:[{
                value:'二崙鄉',
                text:'二崙鄉',
            }],
            industries:[{
                value:'經營/人資類',
                text:'經營/人資類',
            }],
            professions:[{
                value:'人力資源人員',
                text:'人力資源人員',
            }],
            userData: {
                name: "",
                gender: "",
                birthdate: "",
                idno: "",
                phoneNumber: "",
                email: "",
                fullAddress: "",
            },
            form: {
                idno: ""
            }
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                next("/error");
            }
        });
    },
    computed: {
        ...mapState(["user", "userInfo"]),
        displayUserImage() {
            return this.userInfo ? this.userInfo.picture : "";
        },
        selectRegion() {
            return this.regions[this.userData.city]?.map(r => { return { value: r, text: r } }) || []
        }
    },
    mounted() {
        this.getUserData();
        this.getCities();
        this.getIndustries();
        this.getProfessions();
    },
    methods: {
        async getCities() {
            const res = await this.$http({
                url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/taiwan-admin-divisions`,
                method: "GET",
            })
            this.cities = Object.keys(res.data).map((key) => { return { value: key, text: key } })
            this.regions = res.data
        },
        async getIndustries() {
            const res = await this.$http({
                url: `${this.apiHost}/identities/v1/merchants/yunlin/industries`,
                method: "GET",
            })
            this.industries = res.data
        },
        async getProfessions() {
            const res = await this.$http({
                url: `${this.apiHost}/identities/v1/merchants/yunlin/occupations`,
                method: "GET",
            })
            this.professions = res.data
        },
        dummyUserData() {
            let result = {
                "memberId": 47,
                "uuid": null,
                "oauthId": "U38a079aadef4a60ec5a6263112248b35",
                "picture": "https://profile.line-scdn.net/0hNwmZpXdREVhQIzrAyWRuD2xmHzUnDRcQKEJdOHIlGmt1RFIHOEFXPSUiH2x4EFIHaBZebiErSW4p",
                "idno": "H223292882",
                "name": "Kay Lai",
                "birthdate": "1988-03-05",
                "phoneNumber": "0921185084",
                "carrier": null,
                "gender": 1,
                "email": "clearsky0305@gmail.com",
                "fullAddress": "桃園市平鎮區",
                "isTyCitizen": 1,
                "createdTs": 1647945287
            }
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({data: result});
                }, 300);
            })
        },
        callUserDataAPI() {
            var config = {
                method: 'get',
                url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
                headers: { }
            };

            return this.$http(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getUserData() {
            this.callUserDataAPI()
            // this.dummyUserData()
            .then(res => {
                this.$store.commit("updateUserInfo", res.data);
                this.userData = res.data;
                this.form.idno = res.data.idno;
            })
            .catch(err => {
                console.log("err: ", err);
                // this.$router.push('/error');
            })
        },
        popSuccess() {
            var popSuccess = document.getElementById("popSuccess");
            popSuccess.style.display = "flex";
        },
        popClose() {
            var popSuccess = document.getElementById("popSuccess");
            popSuccess.style.display = "none";
        },
        callUpdateProfileAPI() {
            // let response = {
            //     data: {
            //         result: "success"
            //     }
            // };
            // let promise = new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(response);
            //     }, 300);
            // });
            // return promise;

            // let data = JSON.stringify(Object.assign({}, this.data));
            let data = Object.assign({}, this.userData);
            if (this.form.idno && !this.userData.idno) {
                data.idno = this.form.idno;
            }
            let config = {
                url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
                method: "PUT",
                data: data
            }
            console.log("config: ", config)
            return this.$http(config);
        },
        save() {
            this.callUpdateProfileAPI()
                .then((res) => {
                    console.log("res: ", res);
                    this.popSuccess();
                    this.$store.commit("updateUserInfo", res.data);
                })
                .catch((err) => {
                    console.log("err: ", err);
                });
        }
    }
};
</script>
